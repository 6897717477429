import React from 'react';

import Article, { SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';

const Content = () => {
  return (
    <div>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        Regardless of keystroke configuration, LiveSession never records passwords (inputs with{' '}
        <Code inline>type=&quot;password&quot;</Code>) or credit card information (all inputs with{' '}
        <Code inline>autocomplete</Code> property starting with <Code inline>cc-</Code>)
      </div>
      <p>
        LiveSession protects end-users privacy on default, so it doesn&apos;t track what people type
        in into inputs. If you want to enable keystrokes tracking, then you have two possible
        methods to do so:{' '}
      </p>
      <SubTitle className="mt-5">1. Enable global keystrokes tracking</SubTitle>
      <p>
        Add <Code inline>keystrokes: true</Code> to your tracking code options object -{' '}
        <Code inline>{`__ls("init", "YOUR_TRACK_ID", { keystrokes: true })`}</Code>. If you use this
        method then LiveSession will track every input in your application (excluding passwords and
        credit cards).{' '}
        <div className="alert alert-primary mb-5 mt-5" role="alert">
          After turning <b>global keystrokes tracking</b> on, You should test this option on your
          development environment first to ensure that all sensitive data is not exposed to our
          tracker.
        </div>
      </p>
      <p>After change, it should look like that:</p>
      <Code margin="1rem 0">
        {`
<!-- LiveSession.io code -->
<script type="text/javascript">
!function(w, d, t, u) {
    if (w.__ls) return; f = w.__ls = function() { f.push ? f.push.apply(f, arguments) : f.store.push(arguments)};
    if (!w.__ls) w.__ls = f; f.store = []; f.v = "0.1b";

    ls = d.createElement(t); ls.async = true; ls.src = u;
    s = d.getElementsByTagName(t)[0]; s.parentNode.insertBefore(ls, s);
}(window, document, 'script', ('https:' == window.location.protocol ? 'https://' : 'http://') + 'cdn.livesession.io/track.js');

// CHECK LINE OF CODE BELOW, IT'S IMPORTANT 
__ls("init", "YOUR_TRACK_ID", { keystrokes: true });
__ls("newPageView") 
</script>
<!-- LiveSession.io code -->
      `}
      </Code>
      <p>
        To exclude input from tracking manually you can add <Code inline>data-ls-disabled</Code>{' '}
        property to your HTML inputs.
        <Code margin="1rem 0" lang="html">
          {`<input data-ls-disabled type="text" placeholder="Sensitive value"/>`}
        </Code>
      </p>
      <SubTitle className="mt-5">2. Whitelist specific inputs on your website</SubTitle>
      <p>
        Another option is to track only specific inputs that are important for you. Add{' '}
        <Code inline>data-ls-enabled</Code> to input which value you want to track.
        <Code margin="1rem 0" lang="html">
          {`<input data-ls-enabled type="text" placeholder="My unicorn name"/>`}
        </Code>
      </p>
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: "How to record user's keystrokes?",
  metaDescription: 'See how to enable global keystroke tracking.',
  canonical: '/help/how-to-record-keystrokes/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped title={frontmatter.metaTitle} related={related} section="Developer" {...frontmatter} />
);
